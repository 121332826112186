.more-video-list {
  display: flex;
  margin: 0 -45px;
}

.more-video {
  width: 25%;
  padding: 0 45px;
  cursor: pointer;
  h4 {
    font-size: 30px;
    font-weight: 600;
    letter-spacing: -0.05px;
    color: #ffffff;
    padding: 20px 0;
    margin-bottom: 20px;
    border-bottom: solid 1px #b3b3b3;
  }
  p {
    font-size: 26px;
    letter-spacing: -0.04px;
    color: #ffffff;
  }
  &:hover,
  &:focus,
  &:active,
  &.selected {
    .img-wrap {
      transition: all 0.2s ease-in-out;
      transform: scale(1.1);
    }
  }
}

.video-player-page {
  position: relative;
}

.more-video-wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.71;
  background-color: #000000;
  padding: 100px;
  z-index: 10;
  overflow: hidden;
  .back-link {
    margin-bottom: 65px;
  }
}

.pause-wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: 0.3);
  padding: 100px;
  z-index: 10;
  overflow: hidden;
  align-items: flex-start;
  .heading {
    font-size: 50px;
    font-weight: bold;
    letter-spacing: -0.08px;
    color: #ffffff;
  }

  .description {
    width: 80%;
    font-size: 32px;
    letter-spacing: -0.05px;
    color: #ffffff;
  }
  .back-link {
    margin-top: 24px;
    margin-right: 20px;
  }

  .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    cursor: pointer;
  }
}

.playback-arrow-list {
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  li {
    position: absolute;
    top: 50%;
    box-shadow: 10px 10px 10px -2px rgba(0, 0, 0, 0.75);
    background-color: rgba($color: #000000, $alpha: 0.3);
    border-radius: 4px;
  }
  .backward {
    left: 30%;
  }
  .forward {
    left: 70%;
  }
}

video::-webkit-slider-thumb {
  display: none !important;
}

.key-codes {
  display: flex;
  flex-wrap: wrap;
  li {
    margin-right: 10px;
  }
}
