.arrow {
  border: solid #fff;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 4px;
}
.arrow-left-icon {
  display: inline-flex;
  align-items: center;
  .line {
    content: "";
    border-top: 5px solid #fff;
    display: inline-block;
    width: 20px;
    margin-left: -10px;
  }
  .arrow-right {
    @extend .arrow;
    transform: rotate(-45deg);
  }

  .arrow-left {
    @extend .arrow;
    transform: rotate(135deg);
  }
}

.play-icon {
  width: 78px;
  height: 78px;
  opacity: 0.88;
  border: solid 5px #ffffff;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  border-radius: 100%;
  justify-content: center;
  .right-trangle {
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
    border-left: 14px solid #404040;
    display: block;
  }
}
