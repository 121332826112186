.back-link {
  font-size: 46px;
  font-weight: 800;
  letter-spacing: -0.07px;
  color: #ffffff;
  display: flex;
  align-items: center;
}

.more-video-link-wrap {
  display: flex;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10;
}
