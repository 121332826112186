.modal-content {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background: #fff;
  width: 500px;
  margin: auto;
  height: 200px;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0.5;
}

body.modal-open {
  overflow: hidden;
}
