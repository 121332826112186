//reset
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

@-ms-viewport {
  width: device-width;
}

body {
  text-align: left;
  padding: 100px 80px 120px;
  font-family: "Open Sans", sans-serif;
  background-image: radial-gradient(circle at 36% 39%, #07e6f2, #00ceda 33%, #008d9d 93%);
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

ul {
  margin: 0;
  padding: 0;
}
p {
  margin: 0;
}

ul,
li {
  list-style-type: none;
}
a {
  text-decoration: none;
}

$spaceamounts: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px;
    }

    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px;
    }
  }
}

.img-wrap {
  img {
    display: block;
    max-width: 100%;
  }
}

.bg-img {
  background: gray center/cover no-repeat;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  margin-top: 0;
}

.hide {
  display: none !important;
}

.zoom-effect {
  transition: all 0.1s ease-in-out;
  box-shadow: 4px 4px 7px 3px #00c3d5;
  &:hover,
  &.selected,
  &:focus {
    transform: scale(1.1);
    outline: none;
  }
}

.d-flex {
  display: flex;
}

.scroll-x {
  overflow-x: auto;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

:root {
  --headerZIndex: 20;
  --pageWrap: 10;
}

main {
  position: relative;
  z-index: var(--pageWrap);
}

video::-internal-media-controls-download-button {
  display: none;
}

video::-webkit-media-controls-enclosure {
  overflow: hidden;
}
