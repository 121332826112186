header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px 80px;
  z-index: var(--headerZIndex);
  background-image: radial-gradient(circle at 36% 39%, #07e6f2, #00ceda 33%, #008d9d 93%);
  height: 90px;
  .home,
  .mindset {
    font-size: 30px;
    font-weight: 600;
    letter-spacing: -0.05px;
    text-align: center;
    color: #ffffff;
    margin-left: 20px;
  }
  .home-link,
  .mindset-text {
    display: flex;
    align-items: center;
  }
}
