.grade-list {
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin: 0 -30px;
  li {
    padding: 0 15px;
    width: 266px;
  }
  .grade {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: normal;
    text-align: center;
    height: 266px;
    border-radius: 8px;
    box-shadow: 4px 4px 7px 3px #00c3d5;
    background-color: #ffffff;
    cursor: pointer;
    color: #ca3366;
    font-size: 30px;
    font-weight: bold;
    margin: 50px 0;

    &.active {
      box-shadow: 4px 5px 4px 2px #007d89;
      background-image: linear-gradient(158deg, #fa76b1 8%, #9e083a 85%);
      color: #fff;
    }
  }
}
